<template>
  <main class="page-countries page-countries-new">
    <PageTitleContainer>
      <PageTitle removeMargin>Aggiungi Paese</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'settings.countries' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <CountryForm :value="country" :feedback.sync="feedback" @submitForm="saveCountry" />
    </div>
  </main>
</template>

<script>

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import CountryForm from '@/views/components/Form/CountryForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    CountryForm,
  },
  data () {
    return {
      country: undefined,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'settings.countries' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveCountry (countryForm) {
      const country = { ...countryForm };

      this.$api.createCountry(country)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
};

</script>
